import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { sendDeleteRequest } from 'state-domains/utils';

import { getRequestOverrides } from './translators';

export function deleteResource(token: string, url: string): Observable<boolean> {
    const ajax = sendDeleteRequest(url, undefined, getRequestOverrides(token));
    return ajax.pipe(switchMap(() => of(true)));
}
